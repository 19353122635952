import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Grading } from '@core/models/grading.model';
import { Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { ConverterUtil } from '@core/utils/converter-util';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GradingsService extends ApiService<Grading> {
  protected endpoint = 'grading';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getItemsAsListOptions(options?: ListOptions): Observable<MapItem[]> {
    return this.list(options).pipe(
      map((response) => {
        return ConverterUtil.listToOption(response?.data);
      }),
    );
  }

  public getGradingsByProduct(productId: string): Observable<Grading[]> {
    const options: ListOptions = {
      perPage: 999,
      filters: [
        {
          field: 'product_id',
          value: productId,
        },
      ],
    };

    return this.index<Grading>(options).pipe(
      map((response) => {
        return response?.data;
      }),
    );
  }

  public getCharacteristicsList(gradingId: string, options?: ListOptions): Observable<MapItem[]> {
    let url = `/${this.endpoint}/${gradingId}/characteristics/list`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiIndexResult<any>>(url).pipe(
      map((result) => {
        return result?.data;
      }),
    );
  }
}
